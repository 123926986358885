.validations {
  .validation {
    padding: $input-padding-y;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  p {
    margin: 0;
  }

  & + .validations {
    margin-top: $input-padding-y;
  }
}

.help-block {
  margin: 0;
  padding: 0;
}

.question-wrapper.large {
  .validations {
    margin-top: $input-padding-y;
  }
}

@each $state, $data in $cep-form-validation-states {
  .has-#{$state} {
    @include form-control-validation(map_get($data, 'color'), map_get($data, 'bordercolor'), map_get($data, 'bgcolor'));
  }
}
