//
// Pager pagination
// --------------------------------------------------

.pager {
  padding-left: 0;
  margin: $line-height-base 0;
  list-style: none;
  text-align: center;
  @include clearfix;
  li {
    display: inline;
    > a,
    > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: $cep-pager-bg;
      border: 1px solid $cep-pager-border;
      border-radius: $cep-pager-border-radius;
    }

    > a:hover,
    > a:focus {
      text-decoration: none;
      background-color: $cep-pager-hover-bg;
    }
  }

  .next {
    > a,
    > span {
      float: right;
    }
  }

  .previous {
    > a,
    > span {
      float: left;
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      color: $cep-pager-disabled-color;
      background-color: $cep-pager-bg;
      cursor: $pagination-disabled-color;
    }
  }
}
