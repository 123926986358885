@keyframes cep-spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@if not($cep-suppress-backdrop-styling) {
  .loader {
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: $cep-spinner-z-index;
    background-color: $cep-spinner-backdrop-color;

    &.fullpage-backdrop {
      position: fixed;
    }

    &.questionnaire-backdrop {
      position: absolute;
      text-align: center;
    }
  }
}

@if not($cep-suppress-spinner-styling) {
  .loader {
    &.questionnaire-backdrop .spinner {
      position: fixed;
      top: 50%;
    }

    .spinner {
      position: fixed;
      top: 50vh;
      left: 50vw;
      z-index: $cep-spinner-z-index;

      width: $cep-spinner-size;
      height: $cep-spinner-size;

      margin-top: -(calc($cep-spinner-size/2));
      margin-left: -(calc($cep-spinner-size/2));

      border-width: calc($cep-spinner-size / 8);
      display: inline-block;
      border-color: $cep-spinner-color-alt;
      border-style: solid;
      border-right-color: $cep-spinner-color;
      border-radius: 50%;

      animation-name: cep-spinner-rotate;
      animation-duration: $cep-spinner-speed;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

// Annotation

.spinner-annotation {
  z-index: $cep-spinner-z-index;
  margin-top: 37.5px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
  left: 0;

  .alert {
    width: auto;
    display: inline-block;
  }
}
.questionnaire-backdrop .spinner-annotation {
  position: fixed;
  top: 50%;
}
.fullpage-backdrop .spinner-annotation {
  position: fixed;
  top: 50vh;
}
