//== Fonts

//** Icon font
$cep-icon-font-path: 'questionnaire/assets/fonts/' !default;
$cep-icon-font-name: 'glyphicons-halflings-regular' !default;
$cep-icon-font-svg-id: 'glyphicons_halflingsregular' !default;
$cep-icon-font-family: 'Glyphicons Halflings' !default;

//== Animation

//** Enable/disable animation for new questions
$cep-enable-new-question-fade-in: true !default;

// questionnaire fade examples
//- right: translate3d(50%, 0, 0);
//- bottom: translate3d(0, 20px, 0);
//- top: translate3d(0, -30px, 0);
$cep-new-question-fade-in-transform: none !default;
$cep-new-question-fade-in-duration: 0.5s !default;

// Spinner

//## Surpress integ
$cep-suppress-spinner-styling: false !default;
$cep-suppress-backdrop-styling: false !default;

$cep-spinner-backdrop-color: rgba(255, 255, 255, 0.8) !default;
$cep-spinner-color: theme-color('secondary') !default;
$cep-spinner-color-alt: gray('200') !default;
$cep-spinner-speed: 1s !default;
$cep-spinner-size: 75px !default;
$cep-spinner-z-index: 100 !default;

// radios and checkboxes
$cep-selection-list-padding: 20px !default;

// assessment-factor-group
$cep-show-assessment-factor-group-question-index: false !default;

// assessment-factor-search
$cep-focus-box-shadow: 0 0 8px rgba($input-focus-border-color, 0.6) !default;
$cep-unicorn-search-remove-btn-size: 22px !default;

// small inputs (eg. text, number, etc)
$cep-short-input-width: 320px !default;

$cep-space-between-questions: map_get($spacers, 2) * 2 !default;

// pager
$cep-pager-bg: $pagination-bg !default;
$cep-pager-border: $pagination-border-color !default;
$cep-pager-border-radius: 15px !default;

$cep-pager-hover-bg: $pagination-hover-bg !default;

$cep-pager-active-bg: $pagination-active-bg !default;
$cep-pager-active-color: $pagination-active-color !default;

$cep-pager-disabled-color: $pagination-disabled-color !default;

// datepicker
$cep-datepicker-color: theme-color('secondary') !default;
$cep-datepicker-bg: $white !default;
$cep-datepicker-box-shadow: $box-shadow !default;

$cep-datepicker-header-btn-color: $white !default;
$cep-datepicker-header-btn-disabled-color: rgba(255, 255, 255, 0.5) !default;
$cep-datepicker-header-btn-disabled-background: transparent !default;

$cep-datepicker-body-btn-color: gray('700') !default;
$cep-datepicker-body-btn-highlighted-color: gray('200') !default;
$cep-datepicker-body-btn-selected-color: $white !default;
$cep-datepicker-body-btn-disabled-color: gray('500') !default;

$cep-datepicker-weekday-color: gray('500') !default;

$cep-datepicker-predefined-btn-color: $white !default;
$cep-datepicker-predefined-btn-bg: $cep-datepicker-weekday-color !default;
$cep-datepicker-predefined-btn-bg-hover: gray('700') !default;

$cep-hint-color: gray('600') !default;

//80% of the bootstrap size
$cep-smaller-headlines-factor: 0.8 !default;
$cep-header-font-sizes: (
  'h1': $h1-font-size,
  'h2': $h2-font-size,
  'h3': $h3-font-size,
  'h4': $h4-font-size,
  'h5': $h5-font-size,
  'h6': $h6-font-size,
) !default;

// radios & checkboxes
$cep-enable-custom-checkbox-radio: false !default;

$cep-custom-checkbox-radio-button-border-color: $input-border-color !default;
$cep-custom-checkbox-radio-button-bg: $input-bg !default;
$cep-custom-checkbox-radio-button-color: $input-color !default;
$cep-custom-checkbox-radio-button-size: $input-height-inner !default;
$cep-custom-checkbox-radio-button-border-radius: $input-border-radius !default;

//alternative icons: \e013 => checkmark; \e014 => cross; \e125 => thumbs-up
$cep-custom-checkbox-icon-code: '\e013' !default;
$cep-custom-checkbox-icon-font-family: $cep-icon-font-family !default;

// validation & feedback

$cep-form-feedback-success-color: theme-color('success') !default;
$cep-form-feedback-success-bgcolor: theme-color('success') !default;
$cep-form-feedback-success-bordercolor: theme-color('success') !default;

$cep-form-feedback-warning-color: theme-color('warning') !default;
$cep-form-feedback-warning-bgcolor: theme-color('warning') !default;
$cep-form-feedback-warning-bordercolor: theme-color('warning') !default;

$cep-form-feedback-error-color: theme-color('danger') !default;
$cep-form-feedback-error-bgcolor: theme-color('danger') !default;
$cep-form-feedback-error-bordercolor: theme-color('danger') !default;

// position of default question validations,
//- does not apply to lists and structs or special widgets
//- does not apply for screens < screen-md
//- possible values: "below", "above" or "aside"
$cep-form-validation-position: 'below' !default;

$cep-form-validation-states: () !default;
$cep-form-validation-states: map-merge(
  (
    'success': (
      'color': $cep-form-feedback-success-color,
      'bgcolor': $cep-form-feedback-success-bgcolor,
      'bordercolor': $cep-form-feedback-success-bordercolor,
    ),
    'warning': (
      'color': $cep-form-feedback-warning-color,
      'bgcolor': $cep-form-feedback-warning-bgcolor,
      'bordercolor': $cep-form-feedback-warning-bordercolor,
    ),
    'error': (
      'color': $cep-form-feedback-error-color,
      'bgcolor': $cep-form-feedback-error-bgcolor,
      'bordercolor': $cep-form-feedback-error-bordercolor,
    ),
  ),
  $cep-form-validation-states
);
