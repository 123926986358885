
.mdc-tab__content{
  font-weight: 700;
  font-size: 12px;
  color: $mid-gray;
}

.mdc-tab{
  height: 40px !important;
  background-color: rgba(220, 220, 220, 0.5) !important; 
  .mdc-tab__text-label {
    min-width: 40px !important;
  
    color: $standard-text-lighter !important;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.mdc-tab--active {
  background-color: $active-tab-color !important;  
  color: $standard-text-color !important;
  .mdc-tab__text-label {
    min-width: 40px !important;
  
    color: $standard-text-color !important;
    font-size: 12px;
    text-transform: uppercase;
  }
  .mdc-tab__content{
    color: $standard-text-color !important;
  }
}

.mdc-tab__text-label {
  letter-spacing: normal !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.tab-container {
  margin-left: 35px;
  margin-right: 35px;
  
  .mat-mdc-tab-body {
    max-height: 550px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-add {
  background-color: $primary-background-color !important;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: $dark-gray !important;
}


  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{    
    flex-grow: 0 !important;
  }
  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }


  .mat-mdc-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
  .mat-tab-group.mat-primary .mat-mdc-tab-link:not(.mat-tab-disabled):focus,
  .mat-mdc-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: #ffffff;
  }

  .account-config{
    .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
    }

    .mat-mdc-tab-labels{
      background-color: white;
    }
  }

  .inner-mat-tab {
    .mat-mdc-tab-labels{
      background-color: transparent !important;
    }

    .mat-mdc-tab-label {
      background-color: #DCDCDC;
      color: #6A6A6A;
      font-weight: 500;
      margin-right: 1px;
      width: 135px;
    }

    .mat-mdc-tab-label-active {
      font-weight: bold;
      color: #26333B;
    }
  }
