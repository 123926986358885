.type-multiselection,
.type-singleselection {
  .radio,
  .checkbox {
    .key {
      color: $text-muted;
      display: none;
    }
  }
}

//radios / checkboxes
.list-radio,
.list-checkbox {
  padding-left: $cep-selection-list-padding;
  padding-right: $cep-selection-list-padding;
  margin-bottom: 0;
  list-style: none;

  &.boolean .radio {
    max-width: 300px;
    display: inline-block;
    min-width: 180px;
  }

  .checkbox,
  .radio {
    .label-input {
      padding-left: 15px;
    }

    .hint {
      padding-left: 27px;
      font-size: $font-size-base * 0.75;
    }
  }

  .radio,
  .checkbox {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  position: initial;
}

.remove-optional-answer-btn {
  display: block;
  margin-top: $input-padding-y;
  margin-left: $cep-selection-list-padding;
}
