@import '../../../node_modules/bootstrap/scss/variables';

//https://developer.mozilla.org/de/docs/Web/CSS/:invalid
:invalid {
  box-shadow: none;
}

// TODO fix line-height sizes
//fix ipad date input widgets (http://stackoverflow.com/a/16853529)
input[type='date'],
input[type='month'] {
  //line-height: $input-height-inner - (2 * $input-padding-y);
  -webkit-appearance: none;
  -moz-appearance: none;
}

.short-input {
  width: $cep-short-input-width;
  display: inline-block;
  vertical-align: top;
}

// on devices: small inputs are 100%
@include media-breakpoint-down(xs) {
  .short-input {
    width: 100%;
  }
}

.form-group {
  margin-bottom: 0;
}
