$assessment-factor-group-state: ('danger', 'warning');

.btn {
  text-transform: uppercase;
  font-size:14px;
  font-weight: 500;
  align-items: center;
}

.type-assessment-factor-group {
  .collapsible-assessment-factor-group-heading {
    cursor: pointer;

    a,
    a label {
      text-decoration: none;
      cursor: pointer !important;
    }

    .question-label {
      display: inline;
    }
  }

  .card-controls {
    text-align: right;

    .btn {
      margin-left: map_get($spacers, 2);
    }
  }

  @if not($cep-show-assessment-factor-group-question-index) {
    .question-index {
      display: none;
    }
  }
}

.card-header-action {
  float: right;
}

button.card-header-action {
  color: inherit;
  background-color: transparent;
  border: none;
  padding-top: 3px;
  padding-bottom: 2px;

  &:focus {
    border-color: $input-focus-border-color;
    outline: none;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

@each $state in $assessment-factor-group-state {
  .card-#{$state} {
    border-color: theme-color($state);

    .card-header {
      color: $white;
      background-color: theme-color($state);
      border-bottom-color: theme-color($state);

      @if ($state == 'danger') {
        background-color: $ui-red-disabled;
        color: $ui-red;
      }
    }

    .card-controls .btn {
      @if ($state=='danger') {
        color: $white;
        background-color: #6c757d;
        border-color: #6c757d;
      } @else {
        color: theme-color($state);
      }
    }
  }
}

.unicorn-chip button {
  &:focus {
    border-color: darken($border-color, 10%);
    outline: none;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}
