.mat-card {
    &--danger {
        background-color: #ff2837 !important;
        color: #fff !important;
    }
}

.mat-mdc-card {
    &--danger {
        background-color: #ff2837 !important;
        color: #fff !important;
    }

    .mat-mdc-card-subtitle {
        padding: 0 1rem 1rem 1rem;
        color: $standard-text-color;
    }
}