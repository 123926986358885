.type-list {
  .list-item-container {
    border-style: solid;
    border-color: $gray-200;
    border-radius: 4px 4px 0 0;
    padding: $input-padding-y $input-padding-x;
    border-width: 1px 1px 0 1px;

    .question-template {
      margin-bottom: $input-padding-y;
    }
  }

  .list-control {
    border: 1px solid $gray-200;
    margin-top: -10px;
    padding: 10px;
    border-radius: 0 0 4px 4px;
  }
}

//LISTS

.type-list .list-item {
  display: flex;
  flex-wrap: nowrap;

  .list-item-content {
    flex-grow: 1;

    &:not(.type-struct) .question-content-wrapper {
      padding-top: 0;
    }
  }
  .list-item-control {
    .btn {
      margin-left: $input-padding-x;
    }
  }

  padding: $input-padding-y 0 0 0;
  .question-container {
    margin-bottom: 0;
  }

  border-bottom: 1px solid $gray-200;
  &:last-of-type {
    border-bottom: none;
  }

  .question-wrapper .question-validation-wrapper {
    padding-top: 0;
  }
}
