.dialog-iframe{
  display: contents;
}

iframe {
  height: 85%;
  width: 100%;
  border: none;
}

.modal-dark-background {
  color: $white-text-color !important;
  border-radius:4px !important;
 
  .mdc-dialog__content {
    background-color: $dark-background-color;
    color: $white-text-color !important;
  }
}

// TODO: Remove this once we've QA in Dev that we didn't break other modals
// .mat-dialog-actions{
//   float:right;
//   margin-right: -1rem;
// }

// .mat-dialog-container .mat-dialog-title {
//     margin-bottom: 8px !important;
//     font-size: 28px !important;
//     font-weight: 700 !important;
//     word-wrap: break-word;
// }

.angular-editor{
  padding-top: 8px !important;
}
.angular-editor-wrapper:focus, .angular-editor-textarea:focus{
  outline-color: rgb(221, 221, 221);
}

// .no-border-dialog {
//   .mat-dialog-container{
//     padding: 0px !important;

//     .mat-dialog-content {
//       margin: 0px !important;
//     }
//   }
// }


.upload-progress-bar {
  &.mat-mdc-progress-bar {
    height: 2em;
    border-radius: 25px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-element {
    border-radius: 25px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-fill::after {
    background-color: green;
    border-radius: 25px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-buffer {
    background-color: lightgreen;
    border-radius: 25px;
  }

  /* remove animation and the dots*/
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-background {
    animation: none;
    background-color: white;
    fill: white;
  }

  .mdc-linear-progress__bar-inner{
    border-top-width: var(--mdc-linear-progress-track-height, 2em) !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__container {
  .mat-mdc-dialog-content {
      color: $standard-text-color;
  }
}

#tolkien_iframe_wrapper {
  height: 100%;
}
