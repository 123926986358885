.question-table {
  .question-table-row-wrapper {
    display: flex;
    flex-direction: row;
  }

  .question-table-row {
    flex-grow: 1;
    vertical-align: top;
  }

  .question-table-header {
    border: 1px solid $gray-200;
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: none;
    .question-table-row-control {
      opacity: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    .question-table-cell {
      vertical-align: bottom;
    }
  }

  .question-table-cell {
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding: 0 $input-padding-x;

    .question-wrapper .question-content-wrapper {
      width: 100%;
      order: 0;
    }
    .question-wrapper .question-annotation-wrapper {
      order: 2; // see widget-product:5.19 hints in tables BELOW the input
    }

    .has-error {
      .question-content-wrapper {
        margin-right: 0;
        margin-bottom: $input-padding-y;
      }
    }

    .short-input {
      width: 100%;
    }

    .unicorn-search {
      min-width: 0;
    }

    .remove-optional-answer-btn {
      margin-left: 0;
    }
  }

  .question-table-row-control {
    padding: $input-padding-y $input-padding-x;
  }

  .question-table-content {
    border: 1px solid $gray-200;
    padding-top: $input-padding-y;

    .question-template {
      margin-bottom: $input-padding-y;
    }
  }

  .question-table-control {
    border: 1px solid $gray-200;
    border-radius: 0 0 $border-radius $border-radius;
    border-top: none;
    padding: $input-padding-y $input-padding-x;
  }
}
