.pi-chevron-down {
  @include icon_override_fa("\f0d7");
}

.pi-chevron-right {
  @include icon_override_fa("\f0da");
}

.pi-chevron-left {
  @include icon_override_fa("\f0d9");
}

.pi-chevron-up {
  @include icon_override_fa("\f0d8");
}


div.cdk-overlay-pane > div.mat-select-panel-wrap {
  margin-top: 3rem;
  margin-left: 1.75rem;
}

//prime-ng auto suggest

.ac-label {
  font-size: var(--mdc-typography-body1-font-size, 16px) !important;
  //padding-left: 25px;
  background-color: transparent !important;
}

.p-button {
  padding: 0.429rem 1.3rem !important;

  .pi {
    font-size: 0.9rem !important;
  }
}

