.mat-mdc-menu-item.mat-menu-header {
  cursor: default;
  margin-top: -8px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #EFEFEF !important;
  height: 56px;

  span {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 20px;
  }
}
