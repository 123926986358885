.mat-mdc-outlined-button.mat-primary:not(.mat-button-disabled) {
  border-color: $primary-button-color !important;
}

.mat-mdc-outlined-button.mat-primary[disabled] {
  border-color: $ui-blue-disabled !important;
  color:  $ui-blue-disabled !important;
}


button:not(.mat-mdc-menu-item) {
  text-transform: uppercase;
}

.btn-link-black {
  color: $standard-text-color !important;
}

.btn-link-cancel {
  color: $dark-background-color;
  background-color: transparent !important;
  text-transform: uppercase;
  font-size:14px;
  font-weight: 500;
  align-items: center;
}

.btn-drag {
  cursor: move !important;
}

.btn-no-padding {
  padding: 0 !important;
}

.btn-link {
  color: $primary-button-color;
  text-transform: uppercase;
  font-size:14px;
  font-weight: 500;
  background-color: transparent;
  align-items: center;
}

.mat-mdc-icon-button {
  color: $dark-gray !important;
}

.mat-mdc-icon-button:disabled {
  color: $light-gray !important;
}

