.case-manager-header {
  background-color: $primary-background-color;
  padding-left: 35px;
  padding-right: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.edit {
    padding-left: 0;
    padding-right: 0;
  }
}

.case-manager-container {
  background-color: $primary-background-color;
  padding-left: 35px;
  padding-right: 35px;
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;

  .case-manager-inner-container {
    display: flex;
    min-height: min-content;
    flex-direction: column;
    width: 100%;
  }
}

.context-menu {


  .menu-item {
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .sub-menu {
    background-color: transparent !important;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 7px;
  }
}


.filter-cases {
  .case-summary-search-icon {
    justify-content: flex-end;
    text-align: center;
    vertical-align: text-bottom;
    color: $dark-gray;
  }

  .case-summary-search-input {
    font-size: 16px;
    vertical-align: text-top;
    opacity: 100;
    color: $dark-gray;
  }

  //.mat-form-field-outline,
  //.mat-form-field-empty.mat-form-field-label,
  //.mat-form-field-label,
  //.mat-form-field-underline,
  //.mat-input-element,
  ::placeholder {
    color: $dark-gray !important;
  }

  // ******************** multi-select-dropdown-list styles ********************
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline:after {
    color: $mrs-primary !important;
  }

}

.additional-selection {
  opacity: 0.75;
}

.multi-select-input-width {
  width: 100%;
}

// mat-form-field.mat-form-field {
//   font-size: 16px;
// }

// .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: $mid-gray;
//   font-size: 2;
// }

// .mat-form-field-appearance-outline .mat-form-field-outline-thick {
//   color: $mid-gray;
// }

// .mat-form-field.mat-focused.mat-primary::after {
//   color: $mrs-primary !important;
// }

// mat-label.multi-select-label-text {
//   color: $dark-gray;
// }

// div#multiSelectDropdown {
//   .mat-form-field.mat-focused .mat-form-field-label {
//     color: $mrs-primary !important;
//   }
// }

// body{
//   background-color: $primary-background-color;
// }
