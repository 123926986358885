// @use '@angular/material' as mat;

// $custom-typography: mat.define-typography-config($font-family: 'Roboto, Open Sans, sans-serif', $body-1: mat.define-typography-level(13px, 24px, 500), $input: mat.define-typography-level(14px, 1.5, 300));
// @include mat.core($custom-typography);

$primary-button-color: #2C6EED;
$primary-background-color: #EFEFEF;

.body {
    background: url(../../assets/images/login-bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: 'Roboto', 'Open Sans', sans-serif;
}

* {
    font-family: 'Roboto', 'Open Sans', sans-serif;
    // font-weight: 300;
}

// body {
//     font-size: 13px;
// }
html,
body,
app-navigation {
    height: 100%;
    width: 100%;
}

app-navigation {
    display: flex;
    margin: 0;
}

app-dashboard {
    // background: #fff;
}

button {
    outline: 0 !important;
}

// Snackbar Styles
@mixin uwp-snackbar-element($background, $text, $rippleOpacity: 0.1) {
    background-color: $background;
    color: $text;

    // .mat-button {
    //   border: none !important;
    // }

    // .mat-button-wrapper {
    //   color: darken($background, 40%);
    //   text-transform: uppercase;
    //   font-weight: 500;
    // }

    // .mat-ripple-element {
    //   background-color: rgba($background, $rippleOpacity);
    // }
}

// .uwp-snackbar {
//   &--error {
//     @include uwp-snackbar-element(#F1432A, #fff);
//     max-width: 45vw !important;
//     padding: 4px 6px !important;
//   }

//   &--warning {
//     @include uwp-snackbar-element(#F97A1F, #fff)
//   }

//   &--success {
//     @include uwp-snackbar-element(#06BC5A, #fff)
//   }

//   &--info {
//     @include uwp-snackbar-element(#3EBFA8, #fff)
//   }

//   &--default {
//     @include uwp-snackbar-element(#efefef, #000)
//   }
// }

// Table Styles
// .mat-row {
//   transition: 0.2s ease-in-out background-color;

//   &:nth-child(even) {
//     background-color: #fcfcfc;
//   }

//   &:hover {
//     background-color: rgba(0, 0, 0, .05);
//     cursor: pointer;
//   }
// }

// .mat-card {
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .02),
//     0 2px 2px 0 rgba(0, 0, 0, .014),
//     0 1px 5px 0 rgba(0, 0, 0, .12) !important;
//   border-radius: 2px;

//   &--danger {
//     background-color: #ff2837;
//     color: #fff;
//   }
// }

// #caseSummaryDetails .mat-tab-body-wrapper{

//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .02),
//   0 2px 2px 0 rgba(0, 0, 0, .014),
//   0 1px 5px 0 rgba(0, 0, 0, .12) !important;
//   border-radius: 2px;
// }

// .mat-icon-button.mat-primary {
//   width: 25px !important;
//   height: 25px !important;
//   min-width: 25px !important;
//   min-height: 25px !important;
//   line-height: 20px !important;
//   border: 1px solid rgba(80, 157, 218, 0.5) !important;
//   color: #509dda !important;

//   &:hover {
//     background-color: #509dda !important;
//     color: #ffffff !important;
//   }
// }

// .mat-button:not(.material-default) {
//   line-height: 30px;
//   padding: 0 10px;
//   min-width: 72px;
//   font-size: 14px;
//   font-weight: 300;
//   border: 1px solid $primary-button-color;
//   height: 32px;

//   i {
//     margin-right: 8px !important;
//   }
// }

// .mat-raised-button:not(.material-default) {
//   // box-shadow: none !important;
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, 0.014), 0 1px 5px 0 rgba(0, 0, 0, .012) !important;
//   line-height: 32px !important;
//   padding: 0 10px !important;
//   min-width: 72px !important;
//   font-size: 14px !important;
//   font-weight: 300 !important;
//   height: 32px;

//   background-color: $primary-button-color !important;

//   i {
//     margin-right: 8px !important;
//   }
// }



.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $dark-gray !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/// - LAYOUT
#main {
    // background-color: #aec;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    /*temporarily commented to fix matselect issue
    // z-index: 9998;
    */
    // background: #FFFFFF url(assets/images/app-bg-1.png) bottom center no-repeat;
    // background-size: 40%;

    width: 100%;
}

#header {
    // background-color: red;
    flex: 0 0 40px;
    user-select: none;
}

#body {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
}

#sidenav {
    flex: 0 0 240px;
    overflow-y: auto;
    background-color: #353D4A;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    user-select: none;
    padding-top: 60px;
}

#sidenav.halfnav {
    flex: 0 0 50px;
}

#sidenav.hidenav {
    // display: none;
    flex: 0 0 0px;
    border-right: none;
}

// #sidenav.hidenav .menu-item {
//     display: none;
// }
.content {
    // background-color: blue;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // overflow-y: auto;
}

.overflow-content {
    background-color: #EFEFEF;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
}

.dialog-iframe-content {
    // background-color: blue;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%
        /*   overflow-y: auto; */
}

#subheading {
    // background-color: yellow;
    flex: 0 0 30px;
    user-select: none;
}

#innercontent {
    display: flex; // flex-direction: row;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient(to bottom, #ece9e6, #f6f5f3);
}

#innercontentNoBackground {
    display: flex; // flex-direction: row;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.innner-container {
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.page-heading {
    background-color: #ffffff;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 35px;
    padding-left: 15px;
    font-size: 27px;
    font-weight: 700;
    //border-bottom: 1px solid #dbdbdb;
    border-top: 1px solid rgb(244, 246, 248);
    box-shadow: 0px 4px 8px -3px rgba(17, 17, 17, .06);
    z-index: 1;

    .toggle-menu {
        margin-right: 25px;
        color: #666;
        cursor: pointer;
    }

    .breadcrumb-arrow {
        margin: 0 15px;
        color: #666;
    }

    .page-heading-icon {
        height: 20px;
        width: 20px;
        margin-top: -4px;
    }

    .page-heading-title {
        margin-left: 5px;
    }

    // & .page-heading-title:first-child {
    //     color: #333;
    //     font-weight: bold;
    // }
    // & .page-heading-title:last-child {
    //    color: #000;
    //    font-weight: bold;
    // }
}

/// - LAYOUT END
.ui-widget {
    font-family: 'Barlow', sans-serif; // font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
    // font-size: 1em;
    font-size: 13px;
}

a,
button {
    cursor: pointer;
}

// .em {
//     color: rgba(211, 58, 58, 0.911);
// }

.p-tree :focus {
    outline: none;
}

.p-tree {
    width: 100%;
    border: none !important;
}

.p-tree .p-tree-loading-mask {
    background: none !important;
}

.p-tree .p-tree-container {
    padding: 0 !important;
}

.p-treenode .p-treenode-label {
    padding: 0 4px !important;
    font-size: 13px;
}

.p-treenode .p-treenode-icon,
.p-treenode .p-tree-toggler,
.p-treenode .p-tree-toggler .p-tree-toggler-icon {
    color: #343434 !important;
    font-size: 13px !important;
    box-shadow: none !important;
}

.p-treenode {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 !important;
    background-color: transparent !important;
}

.p-treenode-content {
    padding: 0 !important;
    background-color: transparent !important;
    border: none;
}

.p-treenode-icon {
    // color: #1C83BE;
    // background: #100a59;
    // background: -moz-linear-gradient(top, #100a59 0%, #8d1e3b 100%);
    // background: -webkit-linear-gradient(top, #100a59 0%, #8d1e3b 100%);
    // background: linear-gradient(to bottom, #100a59 0%, #8d1e3b 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#100a59', endColorstr='#8d1e3b', GradientType=0);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
}

.p-tree-toggler.fa-caret-down {
    // color: #1C83BE;
}

// .p-tree-toggler.fa-caret-right {
//     color: #FFFFFF;
// }
.row.no-gutters {
    margin-left: -15px;
    margin-right: -15px;
}

// .ui-state-highlight {
//     background: #8393e8 !important;
// }
.p-datatable th.ui-state-default {
    background: #DEDCE7;
    color: #333;
    padding: .5em .5em !important;
    border: none;
}

.p-datatable-footer.ui-widget-header {
    background: #EDECF1;
    padding: 0;
    text-align: left;
}

.ui-tabview .ui-tabview-panel {
    padding: 0 .2em;
}

.ui-tabview.ui-tabview-top > .ui-tabview-nav {
    margin: 0 0.2em;
    padding: 0;
    border-radius: 0;
}

.ui-tabview .ui-tabview-nav {
    background: #E7E5E6;
}

.ui-tabview .ui-tabview-nav > li.ui-state-default {
    background: transparent;
    border: none;
    border-radius: 0;
}

.ui-tabview .ui-tabview-nav > li.ui-state-active {
    background: #FFFFFF;
    border-top: 2px solid #1C83BE;
}

.p-tree .p-treenode-droppoint {
    height: 0;
}

.ui-accordion-header.ui-state-default {
    background: #DEDCE7;
}

.ui-accordion .ui-accordion-header.ui-state-active {
    background: #d0cce4;
}

.ui-accordion .ui-accordion-content {
    padding: 0px;
}

.p-tree-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.p-tree-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.p-tree-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.ui-cell-data,
.p-datatable .p-datatable-data > tr > td {
    overflow: hidden !important;
}

.ui-column-title {
    font-weight: bold;
}

.fa-close:before {
    content: "\f00d";
}

p-panel {
    .ui-panel-titlebar.ui-widget-header {
        background: rgba(224, 213, 234, 0.8);
        border: 1px solid rgba(224, 213, 234, 0.8);
        border-radius: 0;
    }

    .ui-panel.ui-widget {
        border-radius: 0;
    }

    .ui-panel .ui-panel-titlebar-icon {
        float: left;
        margin-right: .2em;
        border: none;
        background: none;
    }

    .ui-panel-title {
        font-size: 14px;
        font-weight: bold;
    }

    .ui-panel.ui-widget .ui-panel-titlebar-icon span {
        top: 2px;
    }

    .ui-panel .ui-panel-content {
        padding: 0;
    }
}

// .ui-widget-content {
//     background: transparent;
// }
.gradient-text {
    background: #100a59;
    background: -moz-linear-gradient(top, #100a59 0%, #8d1e3b 100%);
    background: -webkit-linear-gradient(top, #100a59 0%, #8d1e3b 100%);
    background: linear-gradient(to bottom, #100a59 0%, #8d1e3b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#100a59', endColorstr='#8d1e3b', GradientType=0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.p-dialog-flex {
    display: flex;
    flex-direction: column;

    .p-dialog-content {
        flex: 1;
    }

    &--no-padding {
        .p-dialog-content {
            padding: 0 !important;
        }
    }

    &--center-content {
        .p-dialog-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

// Material Dialog Overrides
// .mat-dialog--no-padding {
//   .mat-dialog-content {
//     padding: 0;
//     margin: 0;
//   }

//   .mat-dialog-container {
//     padding: 0;
//   }
// }

// .mat-dialog--flex {
//   .mat-dialog-container {
//     display: flex;
//     flex-direction: column;
//   }
// }

// keyvalue config custom
.productTree {
    height: 93%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(224, 213, 234, 0.15);
}

.subKeyTree {
    height: 270px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(224, 213, 234, 0.15);
}

.reportsTree {
    height: 82%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(224, 213, 234, 0.15);
}

.columnsTree {
    height: 82%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(224, 213, 234, 0.15);
}

.p-footer {
    display: block;
    padding: 2px 10px;
    background-color: #EDECF1;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
}

#toasty {
    .toast {
        border-radius: 5px !important;

        .close-button:after {
            content: "\f057" !important;
            font: normal normal normal 14px/1 "Font Awesome 5 Free" !important;
        }
    }
}

// .disabled-account-row {
//     background-color: rgba(206, 116, 116, 0.451) !important;
// }
// .enabled-account-row {
//     background-color: rgba(101, 230, 154, 0.322) !important;
// }
.dirty-row {
    background-color: rgba(101, 230, 154, 0.322) !important;
}

.ui-dropdown-panel {
    z-index: 10000 !important;
}

p-fileupload {
    .ui-button[icon="fa-upload"] {
        background: #28a745;
        border: 1px solid #28a745;
    }

    // .ui-button[icon="fa-close"] {
    //     background: #bd2130;
    //     border: 1px solid #bd2130;
    // }
}

.p-datatable .p-datatable-data > tr > td.ui-editable-column {
    height: 30px !important;
}

.invalidRow {
    background: #f9efef !important;
}

p-radioButton {
    label {
        margin-top: 6px;
    }
}

// Bootstrap 4 additional classes


.btn.btn-link.custom {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    user-select: none;
}

i.btn-link.fa,
i.btn-link.fas {
    font-weight: 900;
}

.flex {
    display: flex;

    &-fill {
        flex-grow: 1;
    }

    &-1 {
        flex: 1;
    }

    &-align-center {
        align-items: center;
    }

    &-center {
        align-items: center;
        justify-content: center;
    }

    &-column {
        flex-direction: column;
    }
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-auto {
    overflow: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

// Bootstrap 4 additional classes
/// material fix
// .mat-radio-button label {
//   margin-bottom: 0 !important;
// }

// .mat-radio-label-content {
//   padding-left: 4px !important;
//   padding-right: 10px !important;
//   margin-bottom: 4px;
// }

/// material fix
.ui-contextmenu {
    padding: 0;
    border: none;
}

.ui-contextmenu ul {
    background-color: #fff;
    padding: 0;
    font-size: 15px;
}

.ui-contextmenu .ui-menuitem-link {
    padding: 10px;
    text-decoration: none;
}

.ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.87)
}

.ui-state-highlight {
    background: #abcaec !important;
}

// .p-treenode-label .tree-button {
//   background: #abcaec !important;
// }

.p-tree {
    background-color: transparent;
}

.p-tree .p-tree-container {
    p-treenode {
        display: block;

        .p-treenode {
            display: inline-block;

            .p-treenode-content:focus {
                box-shadow: none !important;
            }

            .p-treenode-content.p-highlight {
                .p-treenode-label {
                    background: #abcaec !important;
                }
            }

        }
    }
}

.p-tree .p-treenode {
    float: left;
    clear: both;
}


.ql-toolbar.ql-snow {
    padding: 2px !important;
}


.ql-toolbar {
    background: #f5f5f5 !important;
}

.ql-container {
    background: #ffffff !important;
    font-size: 16px;
}

.ql-editor {
    padding: 0 !important;
    margin-bottom: 20px !important;
}


p-editor .ql-editor {
    padding: 10px !important;
    font-size: 14px !important;
}








// Questions Preview Pane Styles

.question {
    &-container {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .02),
            0 2px 2px 0 rgba(0, 0, 0, .014),
            0 1px 5px 0 rgba(0, 0, 0, .12) !important;
        margin-bottom: 15px;
        border-radius: 2px;
        position: relative;
        // background: #f8f9fa;
        background: #ffffff;
        // font-size: 12px !important;

        .question-header {
            cursor: pointer;
        }

        &.active {
            // background: #E3F2FD;
            // background-color: rgba(224, 247, 250, 0.74);
            background-color: #f3feff;

            .question-header {
                background-color: rgba(224, 247, 250, 0.90);
            }
        }

        .help-text {
            font-size: 12px !important;
            // margin-bottom: 10px !important;
            padding-left: 20px !important;
            position: relative;

            &:before {
                content: "\f05a";
                font: normal normal 900 14px/1 "Font Awesome 5 Free";
                position: absolute;
                left: 0px;
                top: 2px;
            }
        }

        &.repeat-block {
            background: transparent;
            box-shadow: none !important;
            border: 1px dashed $primary-button-color;
        }
    }

    &-header {
        border-bottom: 1px solid #eeeeee;
        // padding: 8px 12px;
        overflow: hidden;
        background-color: #f5f5f5;
        font-size: 12px !important;

        .btn {
            width: 36px;
        }
    }

    &-body {
        padding: 20px;

        &.message-info {
            background-color: #aef5ff !important;
        }

        &.message-warning {
            background-color: #ffde9c !important;
        }

        &.message-danger {
            background-color: #ffa5a9 !important;
        }

        &.message-success {
            background-color: #9affb9 !important;
        }
    }

    &-mapkey {
        line-height: 37px;
    }

    &-text {
        font-size: 16px !important;
        margin-bottom: 10px;
    }

}

.repeat-block {
    overflow: hidden;

    &-container {
        overflow: hidden;
    }

    &-body {
        overflow: hidden;
        border: 1px dotted #da8484;
        padding: 10px;
        margin-bottom: 15px;
        background-color: #f7f1e9;
    }

    &-footer {
        overflow: hidden;
        border-top: 1px dotted #ccc;
        padding-top: 10px;
    }
}

// Questions Preview Pane Styles  ========= END



.w-20 {
    width: 20% !important;
}

.w-33 {
    width: 33% !important;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-65 {
    width: 65% !important;
}


.ql-font.ql-picker,
.ql-code-block,
.ql-header.ql-picker {
    display: none !important;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0 !important;
}

.interviewQuestion:hover {
    background-color: #f5f5f5;
}

#interviewDialog {
    .p-dialog.ui-widget .p-dialog-titlebar {
        text-align: center;
    }

    .p-dialog .p-dialog-titlebar-close {
        display: none;
    }
}

.label-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
    zoom: .70;
    font-weight: 500;
    margin-bottom: 5px;
}

.label-content {
    font-size: 14px;
    color: #000000;
    margin-bottom: 17px;
    padding-bottom: 2px;
    border-bottom: 1px dashed #eee;

    word-break: break-word;
    word-wrap: break-word;
}


// INTERVIEW LEFT NAVIGATION STYLES

.s-l-container {
    font-size: 12px;
    user-select: none;

    .expand-trigger {
        width: 11px;
        flex: 0 0 11px;
        text-decoration: none;
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &.active,
        &:hover {
            color: #007bff;
        }
    }

    .actions {
        width: 70px;
        flex: 0 0 70px;
        text-align: right;

        .btn {
            font-size: 12px;
            opacity: 0.5;
            width: 20px;
            height: 20px;
            margin: 0 !important;
            padding: 0 !important;

            &:hover {
                opacity: 1;
            }
        }
    }


    .s-container {
        padding-left: 2px;
        border-bottom: 1px solid #dddddd;

        // border-left: 1px solid #dddddd;
        &.active {
            background-color: rgba(224, 247, 250, 0.74);
        }

        .s-header {
            height: 30px;
            line-height: 30px;
        }
    }

    .q-l-container {
        // border-left: 1px solid #dddddd;
        margin-left: 4px;

        .q-container {
            padding-left: 5px;

            .q-header {
                height: 30px;
                line-height: 30px;
                border-bottom: 1px dotted #eee;
            }
        }
    }

    .r-l-container {
        border-left: 1px solid #dddddd;
        margin-left: 4px;

        .r-container {
            padding-left: 5px;

            &.active {
                background-color: rgba(224, 247, 250, 0.74);
            }

            .r-header {
                height: 30px;
                line-height: 30px;
                border-bottom: 1px dotted #eee;
            }
        }
    }

}

// END OF INTERVIEW LEFT NAVIGATION STYLES





// MATERIAL OVER RIDES
// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
//   border-color: #0056b3 !important;
// }

// .mat-radio-button.mat-accent.mat-radio-checked.mat-radio-disabled .mat-radio-outer-circle {
//   border-color: rgba(0,0,0,.38) !important;
// }

// .mat-radio-outer-circle {
//   height: 16px !important;
//   width: 16px !important;
//   border-width: 1.5px !important;
// }

// .mat-radio-button.mat-accent .mat-radio-inner-circle {
//   background-color: #0056b3 !important;
// }

// .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
//   background-color: rgba(0,0,0,.38) !important;
// }

// .mat-radio-checked .mat-radio-inner-circle {
//   transform: scale(0.45) !important;
// }

// .mat-radio-inner-circle {
//   height: 16px !important;
//   width: 16px !important;
// }

// .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
//   background-color: #0056b3 !important;
// }

// .mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb {
//   background-color: #E9E9E9 !important;
// }

// .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
//   background-color: rgba(0, 86, 179, .5) !important;
// }

// .mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar {
//   background-color: #D3D3D3 !important;
// }

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/


.cdk-overlay-container {
    z-index: 1000 !important;
}

// Case Summary
.case-status-select {
    font-size: 14px !important;

    .mat-mdc-form-field-infix.mat-form-field-infix {
        padding: 0.25em 0 !important;
        margin-top: -10px !important;
    }
}

// Case Summary



// .report-date-input {
//   .mat-mdc-form-field-infix {
//     padding: 0.25em 0 !important;
//     min-height: 45.1px !important;
//   }
// }

// END OF MATERIAL OVER RIDES

.badge-dark {
    background-color: rgba(0, 0, 0, .7);
}

.text-word-wrap {
    word-wrap: break-word;
}


// experiment

.ac-container {
    // height: 42px;
    display: block;
    margin-bottom: 15px;
    //background-color: whitesmoke !important;  
}

.ac-label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
    zoom: .65;
    font-weight: 500;
    margin-bottom: 0;
    display: block;
    // height: 24px;
    line-height: 14px;
}

.ac-container.multiple .ac-label {
    line-height: 26px;
}

.ac-component {
    width: 100%;
}

// display: block;

.p-autocomplete-panel {
    border-radius: 2px !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 2px 2px 0 rgba(0, 0, 0, 0.014), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;

    max-width: 960px;
    top: 0px;
}

//ul

.p-autocomplete-list-item {
    font-size: 14px !important;
    font-weight: 400 !important;
    border-radius: 2px !important;
    padding: 5px 10px !important;
    border-bottom: 1px solid #eee !important;
    color: #333333 !important;
    margin: 1px 0 !important;

    &:last-child {
        border-bottom: none !important;
    }

    &.ui-state-highlight {
        background: $primary-button-color !important;
        color: #ffffff;
    }
}

.p-autocomplete-dropdown {
    position: absolute !important;
    right: 0px !important;
    background-color: transparent !important;
    border: none !important;
    height: 28px !important;
    width: 16px !important;
    color: rgba(0, 0, 0, .87) !important;
    /* Material Dropdown */
}

// }

.ac-input {
    padding-left: 0 !important;
    padding-right: 16px !important;
    border: none !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    width: 100%;
    line-height: 28px;
    background-color: transparent !important;

    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;

    &:focus {
        border-bottom: 1px solid $primary-button-color !important;
        box-shadow: none !important;
    }
}

.ac-container.error .ac-input {
    border-bottom: 1px solid #ff0000 !important;
}



.p-autocomplete-multiple-container {
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    border-radius: 0 !important;
    padding: 0 !important;
    font-size: 14px !important;
    width: 100%;
    line-height: 18px;
    background-color: transparent;

    &:focus {
        box-shadow: none !important;
        border-bottom: 1px solid #673ab7 !important;
    }

    .ac-input {
        border: none !important;

        &:focus {
            border: none !important;
        }
    }

    .ui-state-highlight {
        border-color: $primary-button-color !important;
        background: $primary-button-color !important;
        border-radius: 2px !important;
        margin-right: 4px !important;
    }

    .p-autocomplete-token-label {
        margin-right: 18px !important;
        font-size: 14px !important;
    }
}

.ac-container.error .p-autocomplete-multiple-container {
    border-bottom: 1px solid #ff0000 !important;
}

.ac-container.error .p-autocomplete-multiple-container .ac-input {
    border: none !important;
}

.p-autocomplete-multiple-container.ui-inputtext.ui-state-focus {
    box-shadow: none !important;
}

.p-autocomplete .ui-inputtext {
    background: transparent;
}

.ui-calendar {
    height: 32px;

    .ui-inputtext {
        height: 32px;
    }

    .ui-datepicker {
        min-width: 20em !important;
    }
}

.ui-calendar-w-btn {
    display: flex;
    align-items: center;
}

.ui-calendar .ui-calendar-button {
    &:focus {
        outline: none;
    }

    padding: 2px;
    height: 32px;
}

.p-autocomplete-panel {
    z-index: 10000 !important;
}


// experiment


// .call-manager {
//     .ui-state-highlight {
//         background: #ededed !important;
//         color: #222222;
//     }
// }

.popup-editor-container {
    font-family: monospace;
    font-size: 14px !important;

    width: 800px;
    max-width: 800px;
    min-width: 800px;

    height: 500px;
    min-height: 500px;
    max-height: 500px;

    border: 1px solid #ccc;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.expand-button {
    right: 0;
    top: 0px;
}


.report-table {
    td {
        overflow: hidden;
    }
}

.rule {
    &-success {
        background-color: rgba(154, 255, 185, 0.5) !important;
    }

    &-fail {
        background-color: rgba(255, 165, 169, 0.5) !important;
    }
}

// force all buttons with solid fontawesome icons to 900 or they won't show
button.fas {
    font-weight: 900 !important;
}

/* Sets the underline to red if dirty and invalid */
// .mat-form-field.ng-dirty.ng-invalid .mat-form-field-underline {
//   background-color: red;
// }

// /* Sets the label to red if dirty and invalid */
// .mat-form-field.ng-dirty.ng-invalid .mat-form-field-label {
//   color: red;
// }

.error-text {
    font-size: 75%;
}

.invalid-question {
    border: 1px solid red;
    background-color: #f8d7da;
}

/* Flex Helpers */
.flex-1 {
    flex: 1;
}

/* Integration Action Configuration */
.action-mapkey-dropdown {
    .ac-label {
        visibility: hidden;
    }
}

/* Flex Helpers */
.flex-fill {
    min-height: 0;
}

.full-height {
    height: 100%;
}

body .ui-widget {
    font-size: 13px !important;
}

.text-uppercase {
    text-transform: uppercase;
}

// metrics
// .metrics-tabs {
//   .mat-tab-header {
//     background-color: #f8f9fa !important;
//   }
// }

.drag-drop-box {
    padding: 5px 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 13px;
    border-top: solid 1px #ccc;

    &.active {
        background-color: rgba(224, 247, 250, 0.74);
    }

    .s-header {
        border-top: none;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
    }

    .q-header {
        border-top: none;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
    }

    .no-border {
        border-top: none;
    }
}

.drag-drop-list {
    max-width: 100%;
    min-height: 30px;
    display: block;
    border-radius: 4px;

    &.active {
        background-color: rgba(224, 247, 250, 0.74);
    }

    .r-l-container {
        padding-left: 4px;

        .r-container {
            padding-left: 5px;

            .r-header {
                height: 30px;
                line-height: 30px;
                font-size: 13px;
                padding-left: 10px;
            }
        }
    }

    .expand-trigger {
        width: 11px;
        flex: 0 0 11px;
        text-decoration: none;
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &.active,
        &:hover {
            color: #007bff;
        }
    }
}

.drag-handle {
    cursor: move;
}

.cdk-drag-preview {
    background: white;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-custom-placeholder {
    background: $lighter-gray;
    border: 0;
    min-height: 30px;
}

.cdk-drag-placeholder {
    cursor: move;
}

.section-container {
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
}

// chips
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-chip-remove--large-close {
    font-size: 16px;
    margin-top: 3px;
}

.text-smaller {
    font-size: smaller;
}

.version-status {
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 5px;
    color: #227093;
    font-weight: bold;

    &-active {
        color: #048a0a;
    }

    &-inactive {
        color: #6c757d;
    }
}

.bold-label-18 {
    font-weight: 800;
    font-size: 18px;

    &-description {
        font-size: 14px;
        color: grey;
        font-weight: 100;
        margin-left: 10px;
    }
}

.bold-label-16 {
    font-weight: 500;
    font-size: 16px;

    &-description {
        font-size: smaller;
        color: grey;
        font-weight: 100;
        margin-left: 10px;
    }
}

// textarea.mat-input-element {
//   background-color: $white-text-color;
//   border-style: solid;
//   border-width: thin;
// }

.page-header {
    font-size: 27px;
    font-weight: bold;
}

.page-sub-header {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 5px;
}

.section-header {
    font-size: 19px;
    font-weight: bold;
}

.text-link {
    color: $primary-button-color;
    cursor: pointer;
}


/* Tables
================================== */
.Rtable {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 3em 0;
    padding: 0;
}
.Rtable-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%; // Default to full width
    //padding: 0.8em 1.2em;
    overflow: hidden; // Or flex might break
    list-style: none;
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
        margin: 0;
    }

    &.full-width {
        width: 100% !important;
    }
}

/* Table column sizing
================================== */
.Rtable--2cols > .Rtable-cell {
    width: 50%;
}
.Rtable--3cols > .Rtable-cell {
    width: 33.33%;
}
.Rtable--4cols > .Rtable-cell {
    width: 25%;
}
.Rtable--5cols > .Rtable-cell {
    width: 20%;
}
.Rtable--6cols > .Rtable-cell {
    width: 16.6%;
}


.full-width {
    width: 100%
}

.p-editor-inline {
    flex: 1;
    width: 450px;
}

.snack-bar-panel {
    background: rgba(239, 239, 239, 0.9);
    font-size: 16px;
    color: #121212;
    font-weight: 400;
}

/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}