@import 'src/sass/utilities/variables';

// This is a style specificly for the Zendesk widget and to
// move it over a little bit to prevent overlap with Hotjar's widget.
// It's specifically targeting the path the widget is expected to be injected.
body > div > iframe#webWidget {
    margin-right: 2rem;
}

/**
 * Sub-sections are sections of content within a page/tab that have a title and a content block.
 * Example: Case-Summary -> Underwriting Tab -> Requirements & Notes
 **/
.sub-section {
    .sub-section-header {
        display: flex;
        margin-bottom: 0.5rem;

        .title {
            flex: 1 80%;

            font-weight: $font-weight-strong;
            color: $standard-title-color;
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin: 0;
            padding-bottom: 1rem;
        }
        .actions {
            flex: 1 0 15rem;
            text-align: right;
        }
    }
}

.mat-horizontal-content-container {
    padding: 0 0 24px 0 !important;
}