@use '@angular/material' as mat;
@import '@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$mrs-brand-primary: (
    50 : #e6eefd,
    100 : #c0d4fa,
    200 : #96b7f6,
    300 : #6b9af2,
    400 : #4c84f0,
    500 : #2c6eed,
    600 : #2766eb,
    700 : #215be8,
    800 : #1b51e5,
    900 : #103fe0,
    A100 : #ffffff,
    A200 : #dae1ff,
    A400 : #a7b8ff,
    A700 : #8ea3ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$mrs-brand-accent: (
    50 : #e0e0fb,
    100 : #b3b3f6,
    200 : #8080f0,
    300 : #4d4de9,
    400 : #2626e5,
    500 : #0000e0,
    600 : #0000dc,
    700 : #0000d8,
    800 : #0000d3,
    900 : #0000cb,
    A100 : #f3f3ff,
    A200 : #c0c0ff,
    A400 : #8d8dff,
    A700 : #7474ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$mrs-brand-error: (
    50 : #f2e1e1,
    100 : #deb4b4,
    200 : #c88383,
    300 : #b15151,
    400 : #a12b2b,
    500 : #900606,
    600 : #880505,
    700 : #7d0404,
    800 : #730303,
    900 : #610202,
    A100 : #ff9191,
    A200 : #ff5e5e,
    A400 : #ff2b2b,
    A700 : #ff1212,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mrs-primary-palette: mat.define-palette($mrs-brand-primary);
$mrs-accent-palette: mat.define-palette($mrs-brand-primary);
$mrs-error-palette: mat.define-palette($mrs-brand-error);


// include the custom theme object into the angular material theme

$mrs-typography: mat.define-typography-config(    
    $font-family: 'Roboto, Open Sans, sans-serif', 
    $headline-5: mat.define-typography-level(28px, 34px, 700),
    $headline-6: mat.define-typography-level(24px, 29px, 700),
    $subtitle-1: mat.define-typography-level(19px, 24px, 700),
    $body-1: mat.define-typography-level(16px, 24px, 400),
    $body-2: mat.define-typography-level(16px, 20px, 400), 
    /* TODO(mdc-migration): No longer supported. Use `body-1` instead. $input: mat.define-typography-level(16px, 1.5, 400), */
);

$mrs-theme: mat.define-light-theme((
    color: (
        primary: $mrs-primary-palette,
        accent: $mrs-accent-palette,
        warn: $mrs-error-palette),
    typography: $mrs-typography,
 ));

@include mat.all-component-themes($mrs-theme);