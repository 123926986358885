@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '@angular/material/theming';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../../node_modules/quill/dist/quill.core.css';
@import '../../node_modules/quill/dist/quill.snow.css';
@import '../../node_modules/quill/dist/quill.bubble.css';

@import './utilities/variables.scss';
@import './utilities/mixins.scss';
@import './legacy/styles.scss';
@import './themes/theme.scss';

@import './base/typography.scss';
@import './base/material_overrides.scss';

@import './pages/global.scss';
@import './pages/map-keys-dropdown.component.scss';
@import './pages/account-integrations.component.scss';
@import './pages/management-queues.component.scss';
@import './pages/primoris-overrides.component.scss';
@import './pages/hanover-overrides.scss';

@import './components/modal.scss';
@import './components/chip.scss';
@import './components/stepper.scss';
@import './components/dropdown.scss';
@import './components/datatable.scss';
@import './components/dialog.scss';
@import './components/button.scss';
@import './components/card.scss';
@import './components/select.scss';
@import './components/table.scss';
@import './components/tabs.scss';
@import './components/common.scss';
@import './components/snackbar.scss';
@import './components/menu.scss';
@import './components/treeview.scss';
@import './components/formfield';

@import './cep-questionaire/questionnaire.scss';
@import './magnum/magnum.scss';

@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/animate.css/animate.min.css';
