.type-group {
    box-shadow: none !important;
    background-color: inherit !important;
    border-radius: 0% !important;
}

.radio {
    display: block !important;
}

.question-label {
    .question-index {
        display: none !important;
    }
}

.question-index{
    display: none !important;
}