.ml-2 {
  margin-left: 2rem !important;
}

.primary-background {
  background-color: $primary-background-color;
}

textarea.mat-mdc-input-element {
  border-style: none !important;
}

.page-container {
  width: 100%;
  min-width:100%;
  max-height: 89%;
  overflow-y: scroll;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

// .mat-form-field-ripple
// {
//   background-color:  $mrs-primary !important;
// }

.right-panel-body {
  padding-bottom: 75px !important;
  padding-right: 50px !important;
}

.mat-drawer-container{
  background-color: $primary-background-color !important;
}