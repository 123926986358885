.mat-mdc-header-cell{
  font-weight: 700 !important;
  font-size: 14px !important;
  color: black !important;
}

tr.mdc-data-table__row:hover{
  background-color: $primary-background-color-50percent !important;
}

.mat-mdc-row:hover{
  background-color: $primary-background-color-50percent !important;
}

.link-row{
  cursor: pointer;
}
.cell-icon {
  font-size: 14px !important;
  font-weight: 400 !important;
  em{
    padding-left:4px;
  }
}

th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type {
  padding-left: 10px !important;
  padding-right: 5px !important;
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
  padding-bottom: 50px !important;
}

.mat-mdc-paginator-container {
  font-size: 16px !important;
  color: black;
  font-weight: 400;
  background-color: $primary-background-color;

  .mat-mdc-select-value {
    font-size: 16px !important;
    font-weight: 400;
  }
}

.material-icons {
  vertical-align: middle;
}

.table-cell-sub-text {
    font-size:12px;
    line-height: 15px;
    padding-bottom: .5rem;
    color: $mid-gray;
}

.row-height{
  height: 4rem !important;
  min-height: 3.25rem !important;
}

tr.mat-row:hover{
  background-color: $primary-background-color-50percent !important;
}
