.number-with-unit {
  .input-group-addon,
  .input-group-btn .btn {
    min-width: 4.5rem;
  }
}

.unit-help-text {
  margin-bottom: calc($input-padding-y / 2);
}

.tab-content {
  border-left: 1px solid $nav-tabs-border-color;
  padding: $input-padding-y $input-padding-x;
  border-top: 0;
}

.uib-tab.nav-item.disabled:not(.active) {
  display: none; // hide other unit-tabs when question is readonly
}

.special-number-widget {
  .short-input {
    margin-right: map_get($spacers, 2);
  }
  .short-answer {
    margin-right: $input-padding-x;
  }
}

.special-number-widget {
  .nested-validations {
    .validation {
      margin-top: $input-padding-x;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.nav-tabs span.nav-item {
  background-color: transparent;

  &:focus {
    border-color: darken($border-color, 10%);
    outline: none;
    box-shadow: $cep-focus-box-shadow;
  }
}
