.error-centered-text {
  color: $error-red !important;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.standard-centered-text {
  color: $standard-text-color;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.a-button {
  color: $dark-gray
}

.btn.btn-link.standard {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  user-select: none;
}

.main-page-heading {
  margin-top: 48px;
  margin-bottom: 30px;
  font-size: 27px;
  color: black;
  font-weight: 700;
}

.sub-heading {
  font-size: 19px;
  font-weight: 700;
}

.warning-text {
  color: $warning-orange;
  font-size: 12px;
  font-weight: 700;
}

.warning-icon-tab {
  color: $warning-orange;
  font-size: 14px;
  height: 14px !important;
  width: 14px !important;
}

.large-warning-icon {
  color: $warning-orange;
  font-size: 85px;
  line-height: 90px;
  width: 85px !important;
  height: 85px !important;
}

.error-display-message {
  background-color: $error-page-background-color;
  color: $error-red;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  border-radius: 8px;
}

h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}

h2 {
  font-size: 23px !important;
  font-weight: 700 !important;
  line-height: 29px;
}

h3 {
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
}

h4{
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
}

.mat-icon-gray {
  color: $dark-gray;
  font-size: 16px;
}

.small-text {
  font-weight: 400;
  font-size: 12px;
  color: $dark-background-color;
}

.small-error-icon {
  color: $error-red;
  font-size: 12px;
  height: 12px !important;
  width: 12px !important;
  margin-bottom: 2px;
}

.error-text-tab {
  color: $error-red;
}

.body-platform {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
