.question-wrapper {
  display: flex;
  flex-wrap: wrap;

  .question-label-wrapper {
    width: 100%;
  }

  .question-content-wrapper {
    padding-top: $input-padding-y;
    width: 100%;
  }

  &.large .question-content-wrapper {
    margin-right: 0;
    width: 100%;
  }

  .question-annotation-wrapper {
    width: 100%;

    .annotation.hint {
      margin-top: $input-padding-y;
      color: $cep-hint-color;
      font-size: $font-size-base * 0.75;
    }
  }

  .question-validation-wrapper {
    padding-top: $input-padding-y;
    flex-grow: 1;
  }

  .hint.kb-text {
    color: $cep-hint-color;
    font-size: $font-size-base * 0.75;
  }
}

//default: "below"
.has-error,
.has-info,
.has-warning {
  .question-content-wrapper {
    margin-bottom: $input-padding-y;
  }
}

//validation positions
@include media-breakpoint-up(xs) {
  @if $cep-form-validation-position == 'aside' {
    .question-wrapper .question-content-wrapper {
      width: auto;
    }
    .has-error,
    .has-info,
    .has-warning {
      .question-content-wrapper {
        margin-right: $input-padding-x;
        margin-bottom: 0;
      }
    }
  }
  @if $cep-form-validation-position == 'above' {
    .question-wrapper .question-content-wrapper {
      order: 1;
    }
    .has-error,
    .has-info,
    .has-warning {
      .question-content-wrapper {
        margin-top: $input-padding-y;
        margin-bottom: 0;
      }
    }
  }
}

.question-template {
  margin-bottom: $cep-space-between-questions;
  padding: 10px;
}
