.form-actions{
  background-color: inherit !important;
  border-top: none !important;
}

#payment-form {
  background-color: #fff;
}

#tolkien_window .form-horizontal .control-group > label {
  width: 90px !important;
  text-align: left !important;
}

#tolkien_window .form-horizontal .controls {
  margin-left: 75px !important;
}

#tolkien_window .radio, #tolkien_window .checkbox {
  padding-left: 35px !important;
  padding-top: 3px !important;
}

#city {
  margin-right: 4px !important;
}

#state {
  margin-right: 4px !important
}

#tolkien_window .form-horizontal .form-actions {
  float: right !important;
}

#tolkien_window .btn:first-child {
  text-transform: uppercase !important;
}

#tolkien_window .form-actions {
  margin-top: 0 !important
}

#tolkien_window .container-fluid {
  padding-left: 0 !important;
}

#document > html > body{
  background-color: #fff !important;
}

.body-override {
  background-color: #fff !important;
}

