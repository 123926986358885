$mrs-primary: #2C6EED;
$ui-blue-disabled: #C4D4F5;
$error-red: #900606;
$ui-red: #900606;
$ui-red-disabled: #FFDCDC;
$primary-red: #EB4241;
$primary-green: #1D8F0B;
$lighter-gray: #E9E9E9;
$light-gray: #C4C4C4;
$mid-gray: #6A6A6A;
$more-lighter-gray: #F5F5F5;
// TECH DEBT: This color comes from `mrs-full-table.component.scss` and is also the color being used by `_reboot.scss` which is the base text color through the site.
// Since this value is being used as `$standard-text-color` and it's coming through the site this way, I'm updating to be accurate. The previous color was slightly
// lighter with a blue hue.
$dark-gray: #212529; // formally `#26333B`

$active-tab-color: #DCDCDC;
$snackbar-button-color: $light-gray;


.text-danger {
    color: $error-red !important;
}

// Buttons
$primary-button-color: $mrs-primary;
$primary-button-color-disabled: $ui-blue-disabled;
$primary-flat-button-color: $lighter-gray;
$primary-flat-button-color-disabled: $ui-blue-disabled;
$standard-button-height: 2.25rem;

// background
$background: $mrs-primary;
$background-gray: #e5e5e5;
$primary-background-color: #EFEFEF;
$primary-background-color-50percent: rgba(239, 239, 239, 0.5);
$light-background-color: #FFFFFF;
$medium-background-color: #E0E0E0;
$medium-background-color-60: rgba(224, 224, 224, 0.6);
$dark-background-color: $dark-gray;

// Text
$standard-title-color: #000000;
$standard-text-color: $dark-gray;
$standard-text-lighter: $mid-gray;
$white-text-color: #ffffff;

$chip-selected-background-color: $mrs-primary;
$chip-background-color: $dark-gray;
$chip-gray-background-color: $light-gray;
$chip-background-color-10percent: #2C6EED1A;

$success-green-background: #8CC484;
$success-green-text: #3A4D13;

$warning-orange: #CF6B0F;
$ui-orange: $warning-orange;
$warning-orange-background: #FFD0A4;
$warning-orange-text: #AA2E00;

$error-page-background-color: #FFDCDC;
$error-toast-background-color: #FFDCDC;

$toast-action-button-color: #0000E0;
$toast-line-color: #D31DC1;

$black-text: #121212;

$font-weight-strong: 700;
$font-weight-regular: 400;

$roboto-font-family: 'Roboto', 'Open Sans', sans-serif;

//Bootstrap Overrides
$danger: $ui-red;

// Media Queries
// "Apply the styles in the block if the screen is 480px or less"
// @media only screen and (max-width: $media-max-phone) { /* media styles*/ }
$media-max-phone: 480px;
$media-max-tablet: 768px;
$media-max-small-screen: 1024px;
$media-max-desktop: 1200px;

$font-size: 16px;
$font-size-medium: 14px;