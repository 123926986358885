@import "sass/utilities/variables";

:root {
  // MAGNUM COLORS
  --magnum-color-primary-1: $black-text !important;
  --magnum-color-primary-3: $primary-background-color !important;
  --magnum-color-default-5: $primary-background-color !important;
  --magnum-color-default-3: transparent !important;
  --magnum-widget-background-active: $primary-button-color !important;

  // Borders of magnum buttons and their states
  --magnum-widget-border-normal-default: 0 0 0 1px var(--magnum-color-default-2) !important;
  --magnum-widget-border-normal-hover: 0 0 0 1px var(--magnum-color-default-2) !important;
  --magnum-widget-border-normal-focus: 0 0 0 1px var(--magnum-color-default-2) !important;
  --magnum-widget-border-normal-focus-hover: 0 0 0 1px var(--magnum-color-default-2) !important;
  --magnum-widget-border-active-default: 0 0 0 1px var(--magnum-color-default-2) !important;
  --magnum-widget-border-active-hover: 0 0 0 1px var(--magnum-color-default-2) !important;
  --magnum-widget-border-active-focus: 0 0 0 1px var(--magnum-color-default-2) !important;
  --magnum-widget-border-active-focus-hover: 0 0 0 1px var(--magnum-color-default-2) !important;


  .magnum-section__preamble p {
    font-size: 24px !important;
    font-weight: 700 !important;
    padding-bottom: 12px;
    line-height: 1.8rem;
  }

  .magnum-layout_two-columns .magnum-question__header{
    width:auto !important;
  }

  .magnum-layout_two-columns .magnum-question__body{
    width:auto !important;
  }
  magnum-interview {
    padding: 35px 35px 15px 35px !important;

    magnum-interview-layout {
      margin: 0 !important;
      width: auto !important;
    }

    magnum-form-header {
      display: none !important;
    }

    //Change text of all body descendants of magnum-question
    div,
    p,
    span,
    label {
      font-size: $font-size !important;
      font-weight: $font-weight-regular !important;
    }

    // Set all text to the same color
    div,
    label,
    span,
    h2 {
      color: #000000de;
    }


    magnum-section-header {
      //margin top and bottom 6px
      margin: 6px 0 !important;

      h2 {
        font-size: 19px !important;
        font-weight: $font-weight-strong !important;
        margin-bottom: 8px !important;
        margin-top: 0 !important;
        font-family: Roboto !important;
      }
    }

    magnum-question {
      display: flex !important; //TODO: Check this when we get a response from Magnum
        flex-direction: column !important;

      //Make it look like a card
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .02),
      0 2px 2px 0 rgba(0, 0, 0, .014),
      0 1px 5px 0 rgba(0, 0, 0, .12) !important;
      margin-bottom: 16px !important;
      border-radius: 2px !important;
      position: relative !important;
      background: #ffffff !important;
      padding: 20px !important;

      magnum-static-section {
        padding-top: 15px !important;
      }

      .magnum-question__header {
        padding-top: .375rem !important;
      }

      div[selenium-id="question_body"]{
        padding:0 !important;
      }

      // MAGNUM RADIO BUTTONS
      magnum-radio-button {
        &[selenium-attribute-selected="true"] {
          span[selenium-id="switch_icon"] {
            box-shadow: 0 0 0 1px $primary-button-color !important;
          }
        }

        span[selenium-id="switch_icon"] {
          margin-top: 18px !important;
          width: 12px !important;
          height: 12px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          box-shadow: var(--magnum-widget-border-normal-default);

          &:hover {
            box-shadow: var(--magnum-widget-border-normal-default);
          }

          magnum-icon-radio-circle {
            color: $primary-button-color !important;
            width: 12px !important;
            height: 12px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
          }
        }
      }

      // MAGNUM CHECKBOX
      magnum-checkbox {
        //When checkbox is selected
        &[selenium-attribute-selected="true"] {
          span[selenium-id="switch_icon"] {
            box-shadow: 0 0 0 1px $primary-button-color !important;
            background-color: $primary-button-color !important;
          }
        }
        //Make the checkbox smaller
        span[selenium-id="switch_icon"] {
          margin-top: 18px !important;
          width: 12px !important;
          height: 12px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }

        //Update the checked icon to be white and small
        magnum-icon-check-mark {
          color: $white-text-color !important;
          width: 12px !important;
          height: 12px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }
      }

      // MAGNUM SELECT BUTTONS
      magnum-labelled-button {
        span[selenium-id="choice"] {
          background-color: $primary-background-color !important;
          color: black;
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
          padding:8px 12px !important;
        }

        //Adjust the text in the button
        span[selenium-id="switch_caption"]{
          margin:0;
          font-size:12px !important;
          background-color:transparent !important;
        }

        //When the button is selected
        &[selenium-attribute-selected="true"] {
          span {
            background-color: $primary-button-color !important;
            color: white;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
          }
        }

      }
      .magnum-has-errors magnum-labelled-button span[selenium-id="choice"] {
        background-color: var(--magnum-widget-background-invalid) !important;
      }
    }
  }


  // MAGNUM LIST ITEM
  magnum-vertical-list-item {
    &.magnum-vertical-list-item_selected, &.magnum-vertical-list-item_selected:active, &.magnum-vertical-list-item_selected:focus, &.magnum-vertical-list-item_selected:active:focus {
      background-color: $medium-background-color !important;
    }

    //when hovering over a list item and when hovering over an active list item
    &:hover, &:hover:active, &:hover:focus, &:hover:active:focus {
      background-color: $medium-background-color-60 !important;
    }

    &:active, &:focus {
      background-color: var(--magnum-color-default-5) !important;
    }
  }

  // MAGNUM REPEATING SECTIONS
  .magnum-section-repeating__instances{
    background:transparent !important;
    box-shadow: none !important;
    border: 1px dashed $primary-button-color !important;
    padding:20px !important;

    magnum-repeating-section-instance{
      border: 1px dotted #da8484;
      background: #f7f1e9;
      
          padding-left: 16px !important;
    }
  }

  magnum-inline-section{
    magnum-static-section{
      border:none;
        padding:0;
          padding-left: 16px !important;
    }
    magnum-section-header{
      font-size: h3 !important;
    }
  }
}


