.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field--filled:not(.mdc-text-field--invalid),
.mdc-text-field--focused:not(.mdc-text-field--disabled),
.mdc-text-field--focused:not(.mdc-text-field--invalid) {
    background-color: inherit !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: inherit !important;
}

.mdc-button {
    letter-spacing: normal !important;
}

.mdc-form-field>label {
    margin-bottom: 0 !important;
}

.mat-mdc-form-field-flex {
    height: 48px !important;
}

.textarea-formfield {
    .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
            height: auto !important;
        }
    }
}

.chip-input {
    .mat-mdc-form-field-flex {        
        height: auto !important;
    }
}

.mdc-text-field {
    padding-left: 0 !important;
}

.mdc-notched-outline__leading, .mdc-notched-outline__notched, .mdc-notched-outline__trailing {
    border: none !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
    padding-left: 0 !important;
}