.uwp-snackbar{
  font-weight: 400;
  font-size: 12px;
  // padding-top: 16px !important;
  // padding-left: 16px !important;
  // padding-right: 16px !important;
  // padding-bottom: 17px !important;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper{
    color: $snackbar-button-color;
    text-transform: uppercase;
  }
}

.mat-mdc-snack-bar-action button {
  color: $snackbar-button-color;
  text-transform: uppercase;
}

