
.add-new-sticky{
  position: sticky;
  margin-left: 15px;
  bottom: 0px;
  background-color: white;
  color: $primary-button-color;
  cursor: pointer;
  padding-top: 5px;
  font-weight: bold;
}
