@mixin icon-override_pi($icon) {
    background: none !important;
    font-family: 'primeicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;

    &:before {
        content: $icon;
    }
}

@mixin icon-override_fa($icon) {
    @extend %fa-icon;
    @extend .fas;
    background: none;
    display: inline-block;
    font: normal normal normal 14px/1 "Font Awesome 5 Free";
    color: rgba(0, 0, 0, .54);
    font-size: inherit;
    font-weight: 900;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    &:before {
        content: fa-content($icon) !important;
        font-family: "FontAwesome" !important;
    }
}

/**
 * This mixin is designed to be applyed to "Page" components. These are the top level components that render into the "content" area of the website.
 * The content area is the space below the header and to the right of the main navigation, when open.
 *
 * Usage: Open `my-page.component.scss` and add...
 *
 * ```
 * @import 'src/sass/utilities/mixins';
 *
 * :host {
 *     @include page;
 * }
 * ```
 **/
@mixin ag-page() {
    display: flex;
    flex: 1 100%;
    flex-direction: column;
    background-color: $primary-background-color;

    .ag-page {
        padding: 3rem 2.5rem; // 16px base
        overflow: auto;

        .page-header {
            margin-bottom: 0;
            display: flex;
            align-items: baseline;

            .title {
                font-size: 1.6875rem;
                flex: 0 auto;
                padding-right: 1rem;
            }

            .title-content {
                flex: 1;
                font-size: 1rem;
                font-weight: $font-weight-regular;
            }

            .title-buttons {
                flex: 1;
                text-align: right;
            }
        }

        .page-subheader {
            margin: 0 0 1rem 0;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            
            &.last,
            &:last-child {
                margin-left: auto;
                text-align: right;
                font-size: 0.875rem;
            }
            
            .last span {
                font-size: 0.875rem;
            }
        }

        .page-content {
            display: block;
        }
    }

    .ag-page .page-content .page-section {
        display: block;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        .section-header {
            margin: 0;

            .title {
                margin: 0;
            }
        }

        .section-content {
            margin-top: 1rem;
            padding: 1rem;
            background-color: $light-background-color;

            .empty-state-message {
                font-weight: $font-weight-strong;
                font-size: 1.33rem;
                padding: 4rem 1rem;
                text-align: center;
            }
        }
    }
}