.p-dialog {
  font-size: 13px !important;

  p {
    line-height: 1.5;
    margin: 0;
  }

  .p-dialog-title {
    font-size: 13px !important;
  }
}

.center-bold-dialog-title{
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 27px;
}

.dialog-title {
  font-weight: 700 !important;
  font-size: 27px !important;
}

.hide-dialog{
  .mat-mdc-dialog-container{
    padding: 0px;
  }
}

.mat-mdc-dialog-title{
  margin-bottom: 1rem !important; //mat-dialog-title adds .563rem/9px already; desired padding is 1.75rem
}

.dialog-title-subtext{
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: .5rem;
}
