.mat-custom-chip-black {
  background-color: $chip-background-color !important;
  color: $white-text-color !important;


  .mat-icon{
    color: $chip-gray-background-color !important;
  }
}

.mat-custom-chip-gray {
  background-color: $chip-gray-background-color !important;
  color: $standard-text-color !important;

  .mat-icon{
    color: $chip-background-color !important;
  }
}

.mat-custom-chip-black.mat-chip-selected {
  background-color: $chip-selected-background-color !important;
  color: $white-text-color !important;
}

.mat-custom-chip-gray.mat-chip-selected {
  background-color: $chip-selected-background-color !important;
  color: $white-text-color !important;
}

.modal-chip-list {
  overflow-x: hidden;
  .mat-chip-list-wrapper {
    overflow-x: hidden;
  }
}

.tab-hide-overflow {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    min-height: 200px;
    overflow: hidden;
    padding-top: 20px;
    padding-left: 12px;
    background-color: $primary-background-color;
  }
}

